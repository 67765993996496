import axios from "axios";
import {
  removeAfterTime,
  roundedUp,
} from "../UiComponents/util/common/CommanFunction";

/*-----------------------------daily Generation   Apply Call   ------------------------*/

export const DataAnalystSubmit = async (obj) => {
  console.log(obj);
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_PATH_WEBDYN}${process.env.REACT_APP_ANALYTIC_APPLY}`,
      obj
    );

    return response.data;
  } catch (error) {
    console.error("Error posting data:", error);
    throw error;
  }
};

/*-----------------------------Equipment    Apply Call   ------------------------*/

export const EquipmentApi = async (obj) => {
  console.log(obj);
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_PATH_WEBDYN}${process.env.REACT_APP_ANALYTIC_APPLY}`,
      obj
    );

    return response.data;
  } catch (error) {
    console.error("Error posting data:", error);
    throw error;
  }
};

/*----------------------------- Submit call  ------------------------*/

export const SubmitDataAnalyst = async (data) => {
  try {
    console.log(data, "data");
    // const response = await axios.post(`${process.env.REACT_API_ANALYTIC_SUBMIT}`,data);
    // const response = await axios.post(`${process.env.REACT_APP_API_PATH_WEBDYN}${process.env.REACT_APP_API_ANALYTIC_SUBMIT}`,data);
    const response = await axios.post(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_API_ANALYTIC_SUBMIT}`,
      data
    );

    return response.data;
  } catch (error) {
    console.error("Error posting data:", error);
    throw error;
  }
};

/*--------------------------------Get Widzard call ---------------------- */

export const getDataAnalyst = async (obj) => {
  console.log(obj, "obj");
  try {
    // const response = await axios.post(`${process.env.REACT_APP_API_PATH_WEBDYN}${process.env.REACT_APP_API_WIDGETS_GET}`,obj);
    const response = await axios.post(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_API_WIDGETS_GET}`,
      obj
    );
    return response.data;
  } catch (error) {
    console.error("Error posting data:", error);
    throw error;
  }
};

export const fetchParameterListDetailW = async (data) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH_WEBDYN}${process.env.REACT_APP_PARAMETERLISTW}=${data}`
    );

    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

/*------------------------------------ Energy Performance Apply Changes ------------------------------ */

export const equipmentApplyChanges = async (data) => {
  try {
    // const response = await axios.post(`${process.env.REACT_APP_API_PATH_WEBDYN}${process.env.REACT_APP_API_ANALYTIC_EQUIPMENT_APPLY}`,data);
    const response = await axios.post(
      `${process.env.REACT_APP_API_PATH_WEBDYN}${process.env.REACT_APP_API_ANALYTIC_EQUIPMENT_APPLY}`,
      data
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

/*------------------------------------ Parameter Comaprison Apply Changes ------------------------------ */

export const parameterComparisonApplyChanges = async (data) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_PATH_WEBDYN}${process.env.REACT_APP_PARAMTERCOMPARISON}`,
      data
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

/*-----------------------------------------------------Delete Wizard API----------------------------------*/

export const WizardDeleteCall = async (data) => {
  console.log(data, "data");
  try {
    const response = await axios.delete(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_DELETELIST}/${data}`
    );
    console.log(response.data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

/*------------------------------------------ Specific Yeild ----------------------------- */

export const SpecificYeildChanges = async (data) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_PATH_WEBDYN}${process.env.REACT_APP_API_SPECIFIC_YIELD}`,
      data
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

/*------------------------------------------ Specific Yeild ----------------------------- */

export const SpecificYeildForDaily = async (data) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_PATH_WEBDYN}${process.env.REACT_APP_API_SPECIFIC_YIELD_DAILY}`,
      data
    );
    console.log(response, "response");
    return response.data;
  } catch (error) {
    console.error("Error posting data:", error);
    return [];
  }
};

/*------------------------------------------ Actual Vs Expected ----------------------------- */
export const ExpectedActual = async (data) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_PATH_WEBDYN}${process.env.REACT_APP_API_EXPECTEDACTUAL}`,
      data
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

/*------------------------------------------ EIRA Database based API's ----------------------------- */
export const fetchDailyGenerationEira = async (obj) => {
  const endpint =
    obj.range !== "daily"
      ? process.env.REACT_APP_DAILY_GEN
      : process.env.REACT_APP_DAILY_GEN_WITH_MINUTES;
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_CHART_API_PATH}${endpint}`,
      obj
    );

    if (obj.range !== "custom") {
      // let data = removeAfterTime(response.data, '19:00:00');
      let data = response.data;
      data = data.map((entry) => ({
        ...entry,
        todayEnergy: roundedUp(entry.todayEnergy),
      }));
      return data;
    }

    const data = response.data.map((entry) => ({
      ...entry,
      todayEnergy: roundedUp(entry.todayEnergy),
    }));
    return data;
  } catch (error) {
    console.error("Error posting data:", error);
    return [];
  }
};

export const fetchEnergyPerformanceEira = async (data) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_CHART_API_PATH}${process.env.REACT_APP_ENEGRY_PERF}`,
      data
    );
    if (data.range !== "custom") {
      let data = response.data;
      data = data.map((entry) => ({
        ...entry,
        todayEnergy: roundedUp(entry.todayEnergy),
      }));
      return data;
    }

    const result = response.data.map((entry) => ({
      ...entry,
      todayEnergy: roundedUp(entry.todayEnergy),
    }));
    return result;
  } catch (error) {
    console.error("Error fetching data:", error);
    return [];
  }
};

export const fetchParameterComparisonEira = async (data) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_CHART_API_PATH}${process.env.REACT_APP_PARAM_COMP}`,
      data
    );

    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    return [];
  }
};

export const fetchSpecificYieldEira = async (data) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_CHART_API_PATH}${process.env.REACT_APP_SPECIFIC_YIELD}`,
      data
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    return [];
  }
};
export const fetchPvDgGridEira = async (data) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_CHART_API_PATH}${process.env.REACT_APP_PV_DG_GRID}`,
      data
    );

    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    return [];
  }
};

export const DeemedGenrator = async (data) => {
  // console.log(data,"data")
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_CHART_API_PATH}${process.env.REACT_APP_DEEMED}`,
      data
    );
    console.log(response, "response");
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const ActualVsExpected = async (data) => {
  // console.log(data,"data")
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_CHART_API_PATH}${process.env.REACT_APP_ACUTALVSEXPECTED}`,
      data
    );
    console.log(response, "response");
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const GetSetPoint = async (data) => {
  // console.log(data,"data")
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_CHART_API_PATH}${process.env.REACT_APP_SETPOINT}`,
      data
    );
    console.log(response, "response");
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const GetSpecificYieldVsIrradiationSite = async (data) => {
  // console.log(data,"data")
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_CHART_API_PATH}${process.env.REACT_APP_SP_VS_IRR}`,
      data
    );
    console.log(response, "response");
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const GetSpecificYieldVsIrradiationEqp = async (data) => {
  // console.log(data,"data")
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_CHART_API_PATH}${process.env.REACT_APP_SP_VS_IRR_EQP}`,
      data
    );
    console.log(response, "response");
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const GetPRData = async (data) => {
  // console.log(data,"data")
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_CHART_API_PATH}${process.env.REACT_APP_PR}`,
      data
    );
    console.log(response, "response");
    return response.data;
  } catch (error) {
    throw error;
  }
};

// -----------------------------------------Dashboard Save -----------------------------

export const postDashboardName = async (data) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_ADD_DASHBOARD}`,
      data
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    return [];
  }
};

// -----------------------------------------DELETE Dashboard Save -----------------------------

export const DeleteDashboardName = async (data) => {
  try {
    const response = await axios.delete(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_DELETE_DASHBOARD}/${data}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    return [];
  }
};
export const postpagenationapi = async (data) => {
  // console.log(data,"data")
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_CHART_API_PATH}${process.env.REACT_APP_WIDGET_PAGINATION}`,
      data
    );
    console.log(response, "response");
    return response.data;
  } catch (error) {
    throw error;
  }
};
