import React, { useEffect, useState } from "react";
import TotalCard from "./HeaderCard/TotalCard";

import {
  ContactAnalytics,
  ContactAnalytics3,
  ContactAnalytics4,
  ContactAnalytics5,
  ContactAnalytics6,
} from "./ContentCard/ContactAnalytics";
import Content2 from "./ContentCard/Content2";
import Content1 from "./ContentCard/Content1";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import "./Style.css";
import { FadeLoader } from "react-spinners";
import { getDashboardValues } from "../../Api/DashboardAllAPI";
import axios from "axios";
import {
  Autocomplete,
  Button,
  Card,
  IconButton,
  TextField,
  Tooltip,
} from "@mui/material";
import { fetchSiteDropdownByUser } from "../../Api/CommonApi";
import { EnergyPerfTable } from "./EnergyPerfTable";

export const SaasDashboardNew = () => {
  const [EventStatistcs, setEventStatistcs] = useState();
  const [SiteStatistics, setSiteStatistics] = useState();
  const [dashboardDetails, setDashboardDetails] = useState();
  const [isReferesh, setRefresh] = useState(false);
  const [site, setSite] = useState("");
  const [siteIdName, setSiteId] = useState([]);
  const [responseData, setResponseData] = useState();
  const [loading, setLoading] = useState(true);
  const [chartName, setChartName] = useState("System Interactivity");

  useEffect(() => {
    getAPiDashboardCall();
  }, []);

  const chartList = [
    { label: "System Interactivity", value: "energyPerformance" },
    { label: "Deemed Generation", value: "deemedGenration" },
    { label: "Active Power", value: "activePower" },
    // { label: "Actual ", value: "actual" },
    // { label: "DG PV Grid Management", value: "dgpvGrid" },
  ];

  const GroupedData = (data) => ({
    dashboardDetails: {
      siteCount: data.siteCount,
      utilityCount: data.utilityCount,
      roofTopCount: data.roofTopCount,
      alarmTotalCount: data.alarmTotalCount,
      alarmTodayCount: data.alarmTodayCount,
      totalTodayEnergy: data.totalTodayEnergy,
      sumOfTotalEnergy: data.sumOfTotalEnergy,
      co2: data.co2,
      totalOpenTickets: data.totalOpenTickets,
      totalHoldTickets: data.totalHoldTickets,
      totalCloseTickets: data.totalCloseTickets,
    },
    siteDetails: [...data.siteDetails],
    SiteStatistics: {
      activeCount: data.activeCount,
      warningCount: data.warningCount,
      offlineCount: data.offlineCount,
      downCount: data.downCount,
    },
  });

  const getSiteCall = async () => {
    try {
      let id = sessionStorage.getItem("id");
      let data = await fetchSiteDropdownByUser(id);
      setSite(data[0].siteName);
      setSiteId([...new Set(data)]);
    } catch (e) {
      console.error(e);
    }
  };

  const handleSite = async (data, val) => {
    if (val === null) {
      setSite("");
    } else {
      setSite(val);
    }
  };

  const getAPiDashboardCall = async () => {
    const token = sessionStorage.getItem("jwtToken");
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    axios.defaults.headers.common["allowCredentials"] = true;
    try {
      setLoading(true);
      // let responseData = await SiteDetails();
      let id = sessionStorage.getItem("id");
      let response = await getDashboardValues(id);
      await getSiteCall();
      const data = GroupedData(response);
      setDashboardDetails(data.dashboardDetails);
      setEventStatistcs(data.siteDetails);
      setSiteStatistics(data.SiteStatistics);
      setResponseData(data);
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handleRefresh = () => {
    setRefresh(true);
    const RefreshTimeout = setTimeout(() => {
      setRefresh(false);
    }, 1000);

    return () => clearTimeout(RefreshTimeout);
  };

  const handleChangeChart = (data, event) => {
    console.log(data, event);
    setChartName(event);
  };

  return (
    <>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20%",
          }}
        >
          <FadeLoader color="#000440" loading={true} />
        </div>
      ) : (
        <div>
          {/* 
          
          <div className="card-main-align">
            <div style={{ width: '38vw' }}>
              <TotalCard dashboardDetails={dashboardDetails} />
            </div>
            <div style={{ width: '25vw' }}>
              <Content2 SiteStatistics={SiteStatistics} />
            </div>
            <div style={{ width: '26vw' }}>
              <Content1 EventStatistcs={EventStatistcs} />
            </div>
        
          </div>

          <div className="card-main-align" style={{ paddingTop: '16px' }}>
            <div style={{ width: '58vw' }}>
              <Contact3 name={'daily generation'} /> 
            </div>
          </div>*/}

          <div className="card-main">
            <div style={{ width: "65%" }}>
              <TotalCard dashboardDetails={dashboardDetails} />
            </div>
            <div style={{ width: "30%" }}>
              <Content2 SiteStatistics={SiteStatistics} />
            </div>
          </div>
          <div className="card-main-card" style={{ flexDirection: "column" }}>
            <div style={{ width: "100%" }}>
              <Card
                sx={{
                  width: "96%",
                  height: "fit-content",
                  padding: "5px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
                elevation={0}
              >
                <div>
                  <Tooltip title={site}>
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      size="small"
                      disableClearable
                      value={site}
                      options={siteIdName.map((option) => option.siteName)}
                      onChange={(data, event) => handleSite(data, event)}
                      sx={{ width: "16vw", marginRight: "8px" }} // Reduced width and added margin
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          InputProps={{
                            ...params.InputProps,
                            style: { fontSize: "0.9rem" },
                          }}
                          placeholder="Select Site ..."
                        />
                      )}
                      classes={{ option: "autocomplete" }}
                    />
                  </Tooltip>
                </div>

                {/* chart list dropdown */}
                <div style={{ display: "flex" }}>
                  <Tooltip title={chartName}>
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      size="small"
                      disableClearable
                      value={chartName}
                      options={chartList.map((option) => option.label)}
                      onChange={(data, event) => handleChangeChart(data, event)}
                      sx={{ width: "16vw", marginRight: "8px" }} // Reduced width and added margin
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          InputProps={{
                            ...params.InputProps,
                            style: { fontSize: "0.9rem" },
                          }}
                          placeholder="Select Chart Name ..."
                        />
                      )}
                      classes={{ option: "autocomplete" }}
                    />
                  </Tooltip>
                  <div>
                    <IconButton onClick={handleRefresh} disabled={isReferesh}>
                      <RefreshOutlinedIcon sx={{ color: "black" }} />
                    </IconButton>
                  </div>
                </div>
              </Card>
            </div>

            <div className="card-main-card">
              <div style={{ width: "47.5%" }}>
                <ContactAnalytics
                  name={"daily generation"}
                  siteName={site}
                  siteDetails={
                    siteIdName.filter((item) => item.siteName === site)[0]
                  }
                  refresh={isReferesh}
                />
              </div>
              <div style={{ width: "47.5%" }}>
                <EnergyPerfTable
                  name={"energy performance"}
                  siteName={site}
                  siteDetails={
                    siteIdName.filter((item) => item.siteName === site)[0]
                  }
                  refresh={isReferesh}
                />
              </div>
            </div>
            <div className="card-main-card">
              <div style={{ width: "47.5%" }}>
                <ContactAnalytics4
                  name={"actual Vs expected"}
                  siteName={site}
                  siteDetails={
                    siteIdName.filter((item) => item.siteName === site)[0]
                  }
                  refresh={isReferesh}
                />
              </div>
              <div style={{ width: "47.5%" }}>
                {chartName === "Deemed Generation" ? (
                  <ContactAnalytics5
                    name={"deemed generation"}
                    siteName={site}
                    siteDetails={
                      siteIdName.filter((item) => item.siteName === site)[0]
                    }
                    refresh={isReferesh}
                  />
                ) : chartName === "Active Power" ? (
                  <ContactAnalytics3
                    name={"active power"}
                    siteName={site}
                    siteDetails={
                      siteIdName.filter((item) => item.siteName === site)[0]
                    }
                    refresh={isReferesh}
                  />
                ) : (
                  <ContactAnalytics6
                    name={"dg pv grid management"}
                    siteName={site}
                    siteDetails={
                      siteIdName.filter((item) => item.siteName === site)[0]
                    }
                    refresh={isReferesh}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
