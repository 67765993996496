import React, { useState, useEffect, useRef } from "react";
import {
  Typography,
  Grid,
  Button,
  Paper,
  Box,
  TextField,
  Stack,
  Autocomplete,
  Card,
  Tooltip,
  IconButton,
  MenuItem,
  Menu,
} from "@mui/material";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import FadeLoader from "react-spinners/FadeLoader";
import ExcelDataModal from "./ExcelDataModal";
import { downloadExcelTemplate } from "../../Template/ExcelTemplates/ExcelTemplate";
import * as XLSX from "xlsx";
import dayjs from "dayjs";
// ========Modal================
import { styled } from "@mui/material/styles";
/*------------api call-----------------*/
import {
  fetchEquipmentTable,
  //   Getdropdownvalue,
} from "../../Api/EquipmenttypeApi";
import {
  Equipmentsitedropdowns,
  //   getDownTimeMinutes,
  // getNonCommunicateSites,
  postDgrValueSave,
  getDgrValuesByDate,
} from "../../Api/DgrApi";
import { Getdropdownvalue } from "../../Api/MapsiteApi";
import CustomBreadcrumbs from "../util/components/CustomBread";
import { EquipmentType } from "../util/TextField";
import { Statusdropdown, fetchInstalltionCapacity } from "../../Api/CommonApi";
import CustomSnackbar from "../util/components/CustomSnackbar";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
export default function DailyGeneration({ dailyGeneration, onFileSelect }) {
  const [SiteEquipment, setSiteEquipment] = useState([]);
  console.log(SiteEquipment, "SiteEquipment");
  const [equipmentvalue, setEquipmentValue] = useState([]);
  const [DownTime, setDownTime] = useState("");
  console.log(DownTime);
  console.log(DownTime[0]?.downTime);
  console.log(equipmentvalue);
  const [downn, setdownn] = useState("");
  console.log(downn, "downn");
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [selectedSite, setSelectedSite] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [errorIndex, setErrorIndex] = useState(null);
  const [selectedFileData, setSelectedFileData] = useState([]);
  const [modalvalue, setModalvalue] = useState(true);
  const [excelOpen, setExcelOpen] = useState(false);
  const [equipment, setEquipment] = useState("");
  const [toBackend, setToBackend] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [siteIdName, setSiteId] = useState([]);
  const [sitestatus, setSitestatus] = useState("");
  const [remarks, setRemarks] = useState("");
  console.log(remarks, "remarks");
  const [remarkvalue, setRemarkValue] = useState("");
  console.log(remarkvalue, ">>>>>>>");
  const remarkRefs = useRef([]);
  const [editcall, seteditcall] = useState();
  const [unitsIC, setUnitsIC] = useState([]);
  const [text, setTextField] = useState([]);
  const [isFetchingData, setIsFetchingData] = useState(false);
  const fileInputRef = useRef(null);
  const initalState = {};
  const [stdField, setStdField] = useState(initalState);
  console.log(stdField);
  console.log(stdField[0]?.downtime);
  const [siteMapping, setSiteMapping] = useState({});
  const [statusDrop, setStatusDrop] = useState([]);
  const initialSnack = { open: false, severity: "", message: "" };
  const [snack, setSnack] = useState(initialSnack);
  const [popAnchorEl, setPopAnchorEl] = useState(null); // Menu anchor
  const [popOpen, setPopOpen] = useState(false); // Menu open/close state

  const requiredHeader = [
    "Site Name",
    "Date",
    "Equipment Name",
    "Today Energy (kWh)",
    "Export Energy (MWh)",
    "Import Energy (MWh)",
    "Irradiation",
  ];
  const isOEM = sessionStorage.getItem("userTypeId") == 1;

  const handleTemplateDownload = () => {
    // Define the headers
    const headers = [
      "Site Name",
      "Date",
      "Equipment Name",
      "Today Energy (kWh)",
      "Export Energy (MWh)",
      "Import Energy (MWh)",
      "Irradiation",
    ];

    // Create a worksheet
    const ws = XLSX.utils.aoa_to_sheet([headers]);

    // Create a workbook
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Template");

    // Generate Excel file
    XLSX.writeFile(wb, "DGRValue_Template.xlsx");
  };
  //  const handleUploadExcelpopup = async (setFieldSets, setSnack) => {
  //     const input = document.createElement('input');
  //     input.type = 'file';
  //     input.accept = '.xlsx, .xls';

  //     input.onchange = async (event) => {
  //       const file = event.target.files[0];
  //       if (!file) {
  //         setSnack({
  //           open: true,
  //           severity: "error",
  //           message: "No file selected.",
  //         });
  //         return;
  //       }

  //       const reader = new FileReader();
  //       reader.onload = async (e) => {
  //         try {
  //           const data = new Uint8Array(e.target.result);
  //           const workbook = XLSX.read(data, { type: "array" });
  //           const sheetName = workbook.SheetNames[0];
  //           const worksheet = workbook.Sheets[sheetName];
  //           const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

  //           // Validate headers
  //           const expectedHeaders = [
  //             "Site Name",
  //             "Equipment Name",
  //             "Today Energy (kWh)",
  //             "Export Energy (MWh)",
  //             "Import Energy (MWh)",
  //             "Irradiation",
  //           ];
  //           const headers = jsonData[0];
  //           if (!headers.every((header, index) => header === expectedHeaders[index])) {
  //             throw new Error("Invalid headers in the uploaded file.");
  //           }

  //           // Process data
  //           const processedData = jsonData.slice(1).map((row) => ({
  //             siteName: row[0] || "",
  //             equipmentName: row[1] || "",
  //             todayEnergy: row[2] || "",
  //             exportTotalEnergy: row[3] || "",
  //             importTotalEnergy: row[4] || "",
  //             irradiation: row[5] || "",
  //           }));

  //           setFieldSets(processedData);

  //           // Save data using postDgrValueSave API
  //           try {
  //             const response = await postDgrValueSave(processedData);
  //             if (response.success) {
  //               setSnack({
  //                 open: true,
  //                 severity: "success",
  //                 message: "Excel file uploaded, processed, and saved successfully.",
  //               });
  //             } else {
  //               throw new Error("Failed to save data");
  //             }
  //           } catch (saveError) {
  //             console.error("Error saving data:", saveError);
  //             setSnack({
  //               open: true,
  //               severity: "error",
  //               message: "File processed successfully, but failed to save data.",
  //             });
  //           }
  //         } catch (error) {
  //           console.error("Error processing Excel file:", error);
  //           setSnack({
  //             open: true,
  //             severity: "error",
  //             message: error.message || "Error processing the Excel file.",
  //           });
  //         }
  //       };

  //       reader.readAsArrayBuffer(file);
  //     };

  //     input.click();
  //   };
  const parseExcelFile = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(worksheet);

        // Transform the data to match the required format
        const transformedData = jsonData.map((row) => ({
          siteId: parseInt(row.siteId),
          equipmentId: parseInt(row.equipmentId),
          timestamp: row.timestamp,
          ...(row.todayEnergy !== undefined && {
            todayEnergy: parseFloat(row.todayEnergy),
          }),
          ...(row.irradiation !== undefined && {
            irradiation: parseFloat(row.irradiation),
          }),
          ...(row.importTotalEnergy !== undefined && {
            importTotalEnergy: parseFloat(row.importTotalEnergy),
          }),
          ...(row.exportTotalEnergy !== undefined && {
            exportTotalEnergy: parseFloat(row.exportTotalEnergy),
          }),
        }));

        resolve(transformedData);
      };
      reader.onerror = reject;
      reader.readAsArrayBuffer(file);
    });
  };
  const handleUpload = async (event, setFieldSets, setSnack) => {
    const file = event.target.files[0];
    if (!file) {
      setSnack({
        open: true,
        severity: "error",
        message: "No file selected.",
      });
      return;
    }

    const reader = new FileReader();
    reader.onload = async (e) => {
      try {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(worksheet);

        // Validate headers
        const expectedHeaders = [
          "Site ID",
          "Equipment ID",
          "Date",
          "Today Energy",
          "Irradiation",
          "Import Energy",
          "Export Energy",
        ];
        const headers = Object.keys(jsonData[0]);
        const isValidHeaders = expectedHeaders.every((header) =>
          headers.includes(header)
        );

        if (!isValidHeaders) {
          throw new Error("Invalid headers in the uploaded file.");
        }

        // Transform data to match API requirements
        const transformedData = jsonData.map((row) => {
          const baseData = {
            siteId: parseInt(row["Site ID"]),
            equipmentId: parseInt(row["Equipment ID"]),
            timestamp: formatDate(row["Date"]),
          };

          if (row["Today Energy"] !== undefined && row["Today Energy"] !== "") {
            baseData.todayEnergy = parseFloat(row["Today Energy"]);
          }
          if (row["Irradiation"] !== undefined && row["Irradiation"] !== "") {
            baseData.irradiation = parseFloat(row["Irradiation"]);
          }
          if (
            row["Import Energy"] !== undefined &&
            row["Import Energy"] !== ""
          ) {
            baseData.importTotalEnergy = parseFloat(row["Import Energy"]);
          }
          if (
            row["Export Energy"] !== undefined &&
            row["Export Energy"] !== ""
          ) {
            baseData.exportTotalEnergy = parseFloat(row["Export Energy"]);
          }

          return baseData;
        });

        // Save data using postDgrValueSave API
        const response = await postDgrValueSave(transformedData);
        if (response.success) {
          setSnack({
            open: true,
            severity: "success",
            message: "Excel file uploaded and data saved successfully.",
          });
          // Update fieldSets with the new data
          setFieldSets(transformedData);
        } else {
          throw new Error(response.statusDescription || "Failed to save data.");
        }
      } catch (error) {
        console.error("Error processing Excel file:", error);
        setSnack({
          open: true,
          severity: "error",
          message: error.message || "Error processing the Excel file.",
        });
      }
    };

    reader.readAsArrayBuffer(file);
  };
  const formatDate = (dateString) => {
    // Assuming dateString is in a format that can be parsed by Date
    const date = new Date(dateString);
    return date.toISOString().split("T")[0]; // Returns YYYY-MM-DD
  };

  const handleClick = () => {
    fileInputRef.current.click();
  };
  // const excelDateToJSDate = (serial) => {
  //   const excelStartDate = new Date(1899, 11, 30); // Base date for Excel
  //   const days = Math.floor(serial - 1);
  //   return new Date(excelStartDate.setDate(excelStartDate.getDate() + days));
  // };
  const excelDateToJSDate = (serial) => {
    const excelStartDate = new Date(1899, 11, 30); // Base date for Excel
    const days = Math.floor(serial);
    const excelDate = new Date(
      excelStartDate.setDate(excelStartDate.getDate() + days)
    );

    // Return in 'YYYY-MM-DD' format
    return excelDate.toISOString().split("T")[0];
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = (e) => {
      const data = e.target.result;

      // Read the binary data and process with XLSX
      const workbook = XLSX.read(data, { type: "binary" });
      const firstSheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[firstSheetName];

      // Convert the sheet data to JSON
      const jsonData = XLSX.utils.sheet_to_json(worksheet);
      console.log(jsonData); // Here you can inspect the JSON data
      // Fix the date field in each row and convert to timestamp
      const fixedData = jsonData.map((row) => {
        if (row.Date) {
          // Check if Date is a number (Excel serial date)
          if (typeof row.Date === "number") {
            // Convert serial date to YYYY-MM-DD
            row.Date = excelDateToJSDate(row.Date).toISOString().split("T")[0];
          }
          // Ensure the date is formatted correctly for the API
          row.timestamp = row.Date; // Add the "timestamp" field in the required format
          delete row.Date; // Remove the original "Date" field if needed
        }
        return row;
      });
      // Now post the data to the API
      postDgrValueSave(fixedData); // Call the API function
    };

    reader.readAsBinaryString(file); // Read the file as binary string
  };

  const handleInput = (event) => {
    const files = event.target.files; // Access the uploaded files
    if (files && files[0]) {
      // Ensure there is at least one file
      const file = files[0];
      console.log("File uploaded:", file);
      // Process the file here
    } else {
      console.error("No file selected"); // Handle case where no file is selected
    }
  };

  const hasDataToDownload = () => {
    return (
      selectedSite &&
      fieldSets.some(
        (fieldSet) =>
          fieldSet.equipmentName &&
          (fieldSet.todayEnergy ||
            fieldSet.exportTotalEnergy ||
            fieldSet.importTotalEnergy ||
            fieldSet.irradiation)
      )
    );
  };

  /*----------------------------Fields-------------------------------*/
  const initialFieldSet = {
    equipmentName: "",
    equipmentId: "",
    timestamp: "",
    todayEnergy: "",
    exportTotalEnergy: "",
    importTotalEnergy: "",
    irradiation: "",
    ambientTemperature: "",
  };
  const [fieldSets, setFieldSets] = useState([initialFieldSet]);
  console.log(fieldSets);
  console.log("Field Sets after update:", fieldSets);
  useEffect(() => {
    if (siteIdName?.length > 0) {
      setSelectedSite(siteIdName[0].siteName);
    }
  }, [siteIdName]);
  // const handleSite = async (event, value) => {
  //   setSitestatus(value);
  //   setSelectedSite(value);
  //   const site = siteIdName.find((item) => item.siteName === value);
  //   if (site) {
  //     try {
  //       const equipmentData = await Equipmentsitedropdowns(site.siteId);
  //       const newFieldSets = equipmentData.map((equipment) => ({
  //         equipmentName: equipment.displayName || "",
  //         equipmentId: equipment.equipmentId || "",
  //         todayEnergy: "",
  //         exportTotalEnergy: "",
  //         importTotalEnergy: "",
  //         irradiation: "",
  //       }));
  //       setFieldSets(newFieldSets);
  //       fetchDataForDate(selectedDate);
  //     } catch (error) {
  //       console.error("Error fetching equipment data:", error);
  //       setSnack({
  //         open: true,
  //         severity: "error",
  //         message: "Failed to fetch equipment data",
  //       });
  //     }
  //   }
  // };
  const handleSite = async (event, value) => {
    setSitestatus(value);
    setSelectedSite(value);
    const site = siteIdName.find((item) => item.siteName === value);
    if (site) {
      try {
        const [equipmentData, downtimeData] = await Promise.all([
          Equipmentsitedropdowns(site.siteId),
        ]);
        setEquipmentValue(equipmentData);
        const newFieldSets = equipmentData.map((equipment) => {
          return {
            equipmentName: equipment.displayName || "",
            equipmentId: equipment.equipmentId || "",
          };
        });
        setFieldSets(newFieldSets);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };
  const toolStyle = {
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 10],
        },
      },
    ],
  };
  /*--------------------------Download Excel ----------------------*/

  const handleDownload = () => {
    // Sample data that you want to export (replace this with your actual data)
    const data = fieldSets.map((fieldSet) => ({
      EquipmentName: fieldSet.equipmentName,
      TodayEnergy: fieldSet.todayEnergy || "",
      ExportTotalEnergy: fieldSet.exportTotalEnergy || "",
      ImportTotalEnergy: fieldSet.importTotalEnergy || "",
      Irradiation: fieldSet.irradiation || "",
    }));

    // Define worksheet and workbook
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Equipment Data");

    // Generate Excel file and trigger download
    XLSX.writeFile(workbook, "Equipment_Data.xlsx");
  };
  /*--------------------------Upload Excel ----------------------*/
  const handleFileUpload = (e) => {
    setPopAnchorEl(e.currentTarget); // Set anchor to the button clicked
    setPopOpen(true); // Open menu
  
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const data = new Uint8Array(event.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
  
        // Helper function to convert Excel date serial to JS date
        const excelSerialToDate = (serial) => {
          const daysOffset = serial - 25569; // Excel starts from 1900-01-01
          const date = new Date(daysOffset * 86400 * 1000); // Convert to ms
          return date.toISOString().split('T')[0]; // Return in YYYY-MM-DD format
        };
  
        // Process the data as needed, assuming dates are in the second column (row[1])
        const processedData = jsonData.slice(1).map(row => ({
          siteName: row[0],
          date: typeof row[1] === 'number' ? excelSerialToDate(row[1]) : row[1], // Convert if it's a serial
          equipmentName: row[2],
          todayEnergy: row[3],
          exportTotalEnergy: row[4],
          importTotalEnergy: row[5],
          irradiation: row[6]
        }));
  
        setSelectedFileData(processedData); // Save the processed data
        setFieldSets(processedData); // Store the processed data in fieldSets
        setIsModalOpen(true); // Open modal
      };
  
      reader.readAsArrayBuffer(file); // Read the file as a buffer
    }
  };
  
  // const handleFileUpload = (e) => {
  //   setPopAnchorEl(e.currentTarget); // Set anchor to the button clicked
  //   setPopOpen(true); // Open menu
  //   const file = e.target.files[0];
  //   if (file) {
  //     const reader = new FileReader();
  //     reader.onload = (event) => {
  //       const data = new Uint8Array(event.target.result);
  //       const workbook = XLSX.read(data, { type: 'array' });
  //       const sheetName = workbook.SheetNames[0];
  //       const worksheet = workbook.Sheets[sheetName];
  //       const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

  //       // Process the data as needed
  //       const processedData = jsonData.slice(1).map(row => ({
  //         siteName: row[0],
  //         date: row[1],
  //         equipmentName: row[2],
  //         todayEnergy: row[3],
  //         exportEnergy: row[4],
  //         importEnergy: row[5],
  //         irradiation: row[6]
  //       }));

  //       setSelectedFileData(processedData);
  //       setIsModalOpen(true);
  //     };
  //     reader.readAsArrayBuffer(file);
  //   }
  // };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const validateData = (data) => {
    const validData = [];
    const errorRows = [];

    data.forEach((row, index) => {
      const errors = [];

      // Validate each field according to your requirements
      if (!row["Field1"] || row["Field1"].length > 50)
        errors.push("Field1 error");
      if (!row["Description"] || row["Description"].length > 100)
        errors.push("Description error");
      // Add more validations as required...

      if (errors.length > 0) {
        errorRows.push({ index, errors });
      } else {
        validData.push(row);
      }
    });

    return { validData, errorRows };
  };
  const processUploadedData = (data) => {
    try {
      const processedData = data.map((row) => ({
        equipmentName: row.equipmentName || "",
        todayEnergy: row.todayEnergy || "",
        exportEnergy: row.exportEnergy || "",
        importEnergy: row.importEnergy || "",
        irradiation: row.irradiation || "",
        ambient: row.ambient || "",
      }));

      setFieldSets(processedData);

      setSnack({
        open: true,
        severity: "success",
        message: "Data uploaded successfully",
      });
    } catch (error) {
      console.error("Error processing uploaded data:", error);
      setSnack({
        open: true,
        severity: "error",
        message: "Error processing the uploaded data",
      });
    }
  };

  // const sortedFieldSets = [...fieldSets].sort((a, b) => {
  //   const order = ["inverter", "energymeter", "sensor"];
  //   const aType = order.findIndex((type) =>
  //     a.equipmentName.toLowerCase().includes(type)
  //   );
  //   const bType = order.findIndex((type) =>
  //     b.equipmentName.toLowerCase().includes(type)
  //   );
  //   return aType - bType;
  // });

  const getEquipment = async (id) => {
    // const data = await Equipmentsitedropdowns(id);
    // setEquipmentValue(data);
    // return(data)
  };

  /*------------------------- Api --------------------------------------------*/
  useEffect(() => {
    fetchData();
    fetchStatus();
    fetchUnits();
    fetchSiteList();
  }, []);
  useEffect(() => {
    let data = EquipmentType();
    setTextField(data);
  }, []);
  useEffect(() => {
    console.log("Field Sets updated:", fieldSets);
  }, [fieldSets]);
  const fetchStatus = async () => {
    const data = await Statusdropdown();
    if (Array.isArray(data)) {
      setStatusDrop(data);
    } else if (typeof data === "object" && data !== null) {
      setStatusDrop([data]);
    }
  };
  const fetchUnits = async () => {
    try {
      let data = await fetchInstalltionCapacity();
      console.log(data, "dataOutside");
      setUnitsIC(data);
    } catch (error) {
      console.error(error);
    }
  };

  // useEffect(() => {
  //   fetchSiteList();
  //   if (selectedSite) {
  //     fetchDataForDate(selectedDate);
  //   }
  // }, [selectedSite, selectedDate]);
  useEffect(() => {
    if (siteIdName?.length > 0) {
      setSelectedSite(siteIdName[0].siteName);
      fetchDataForDate();
    }
  }, [siteIdName]);


  const fetchSiteList = async () => {
  try {
    const id = sessionStorage.getItem("id"); 
    // let data = await getNonCommunicateSites(id); 
    let data = await Getdropdownvalue(id); 
    
    if (Array.isArray(data)) {
      const dynamicSiteMapping = data.reduce((acc, site) => {
        acc[site.siteName] = site.siteId; 
        return acc;
      }, {});
      
      setSiteMapping(dynamicSiteMapping); 
      setSiteId(data);
      setLoading(false);
    } else if (typeof data === "object" && data !== null) {
      setSiteId([data]);
    }
    setLoading(false);
    await fetchDataForDate(data[0]?.siteId, data[0]?.siteName);
  } catch (e) {
    console.error(e, "errrrrror");
  }
};

  console.log(siteIdName, "siteIdName");
  const fetchDataForDate = async (date) => {
    setLoading(true);
    try {
      const formattedDate = dayjs(date).format("YYYY-MM-DD"); // Format the date using Day.js
      const site = siteIdName.find((site) => site.siteName === selectedSite); // Find the selected site
      if (!site) {
        throw new Error("Selected site not found");
      }
      const data = await getDgrValuesByDate(site.siteId, formattedDate); // Fetch data based on site and formatted date
      updateFieldSetsWithData(data);
    } catch (error) {
      console.error("Error fetching data for date:", error);
    } finally {
      setLoading(false);
    }
  };

  const updateFieldSetsWithData = (data) => {
    const updatedFieldSets = fieldSets.map((fieldSet) => {
      const matchingData = data.find(
        (item) => item.equipmentId === fieldSet.equipmentId
      );
      if (matchingData) {
        return {
          ...fieldSet,
          todayEnergy: matchingData.todayEnergy,
          exportTotalEnergy: matchingData.exportTotalEnergy,
          importTotalEnergy: matchingData.importTotalEnergy,
          irradiation: matchingData.irradiation,
        };
      }
      return fieldSet;
    });
    setFieldSets(updatedFieldSets);
  };

  const handleDateChange = (date) => {
    const formattedDate = dayjs(date); 
    // const formattedDate = dayjs(date).format('YYYY-MM-DD'); // Format the date as YYYY-MM-DD or any format you prefer
    setSelectedDate(formattedDate); 
    setFieldSets([]); // Clear the fieldSets
    console.log('Selected Date:', formattedDate.format("YYYY-MM-DD")); // Log formatted date
    fetchDataForDate(formattedDate); 
  }; 

  // const handleNumericInput = (event, fieldName, index) => {
  //   const value = event.target.value;

  //   // Allow numbers and up to two decimal places
  //   if (value === "" || /^\d*\.?\d{0,2}$/.test(value)) {
  //     const updatedFieldSets = [...fieldSets];
  //     updatedFieldSets[index] = {
  //       ...updatedFieldSets[index],
  //       [fieldName]: value,
  //     };
  //     setFieldSets(updatedFieldSets);
  //   }
  // };
  const handleInputChange = (event, fieldName, index) => {
    const value = event.target.value;

    // Create a copy of fieldSets and update the specific field
    setFieldSets((prevFieldSets) => {
      const updatedFieldSets = [...prevFieldSets];
      updatedFieldSets[index] = {
        ...updatedFieldSets[index],
        [fieldName]: value,
      };
      return updatedFieldSets;
    });
  };
  const excelSerialToDate = (serial) => {
    const daysOffset = serial - 25569; // Excel serial number offset
    const date = new Date(daysOffset * 86400 * 1000); // 86400 seconds in a day, convert to ms
    return date.toISOString(); // Return in ISO format
  };
  
  /*------------------------- handleSave ---------------------------*/
  const handleSave = async () => {
    const selectedSite = siteIdName.find(
      (site) => site.siteName === sitestatus
    );

    if (!selectedSite || !selectedDate) {
      setSnack({
        open: true,
        severity: "error",
        message: "Please select both a site and a date.",
      });
      return;
    }

    const formattedDate = selectedDate.format("YYYY-MM-DD");

    console.log("Current fieldSets:", fieldSets);

    let data = fieldSets.map((fieldSet) => {
      let payload = {
        siteId: selectedSite.siteId,
        equipmentId: fieldSet.equipmentId,
        timestamp: formattedDate,
      };

      const equipmentNameLower = fieldSet.equipmentName.toLowerCase();
      console.log("Processing equipment:", equipmentNameLower);

      // Helper function to add numeric values (including zero)
      const addNumericValue = (key, value) => {
        const numValue = parseFloat(value);
        if (!isNaN(numValue)) {
          payload[key] = numValue;
        } else if (value === "") {
          payload[key] = 0; // Set empty string to 0
        } else {
          console.log(`Invalid value for ${key}:`, value);
        }
      };

      // Add all relevant fields regardless of equipment type
      addNumericValue("todayEnergy", fieldSet.todayEnergy);
      addNumericValue("exportTotalEnergy", fieldSet.exportTotalEnergy);
      addNumericValue("importTotalEnergy", fieldSet.importTotalEnergy);
      addNumericValue("irradiation", fieldSet.irradiation);

      console.log("Payload for equipment:", fieldSet.equipmentName, payload);
      return payload;
    });

    console.log("Data to be sent:", data);

    if (data.length === 0) {
      setSnack({
        open: true,
        severity: "error",
        message: "No data to save. Please check your inputs.",
      });
      return;
    }

    try {
      const response = await postDgrValueSave(data);
      console.log("Data saved successfully:", response);
      setFieldSets([initialFieldSet]);
      setSelectedDate(null);
      setSitestatus("");
      setSiteId([]);
      setRemarks("");
      setSnack({
        open: true,
        severity: "success",
        message: response.statusDescription || "Data saved successfully",
      });
    } catch (error) {
      console.error("Error saving data:", error);
      setSnack({
        open: true,
        severity: "error",
        message: "Error saving data. Please try again.",
      });
    }
  };

  // const handleSave = async () => {
  //   if (selectedFileData.length === 0) {
  //     console.error('No data to save');
  //     return;
  //   }
  
  //   try {
  //     // Function to convert Excel serial date or date strings to YYYY-MM-DD format
  //     const excelSerialToDate = (serial) => {
  //       if (typeof serial === 'number') {
  //         const daysOffset = serial - 25569;
  //         const date = new Date(daysOffset * 86400 * 1000);
  //         return date.toISOString().split('T')[0]; // Return only the date part (YYYY-MM-DD)
  //       } else {
  //         return new Date(serial).toISOString().split('T')[0]; // Return only the date part (YYYY-MM-DD)
  //       }
  //     };
  
  //     // Transform the selected file data to match the required format
  //     const dataToSave = selectedFileData.map(row => {
  //       const dataObj = {
  //         siteId: siteMapping[row.siteName] || "", // Use dynamic siteId from the state
  //         equipmentId: row.equipmentName || "", // Adjust according to your data structure
  //         timestamp: excelSerialToDate(row.date) // Convert the date to YYYY-MM-DD format
  //       };
  
  //       // Add fields dynamically based on available data in each row
  //       if (row.todayEnergy) {
  //         dataObj.todayEnergy = parseFloat(row.todayEnergy);
  //       }
  //       if (row.irradiation) {
  //         dataObj.irradiation = parseFloat(row.irradiation);
  //       }
  //       if (row.importEnergy) {
  //         dataObj.importTotalEnergy = parseFloat(row.importEnergy);
  //       }
  //       if (row.exportEnergy) {
  //         dataObj.exportTotalEnergy = parseFloat(row.exportEnergy);
  //       }
  
  //       return dataObj;
  //     });
  
  //     // Debugging: Log the data to be saved
  //     console.log('Payload to save:', dataToSave);
  
  //     // Send the data to the backend API
  //     const response = await postDgrValueSave(dataToSave);
      
  //     if (response.success) {
  //       console.log('Data saved successfully');
  //       setIsModalOpen(false);
  //     } else {
  //       console.error('Failed to save data:', response.statusDescription);
  //     }
  //   } catch (error) {
  //     console.error('Error saving data:', error);
  //   }
  // };
  
  
  

  // const handleSave = async () => {
  //   if (selectedFileData.length === 0) {
  //     console.error('No data to save');
  //     return;
  //   }
  
  //   try {
  //     const excelSerialToDate = (serial) => {
  //       if (typeof serial === 'number') {
  //         const daysOffset = serial - 25569; // Adjust serial to date offset
  //         const date = new Date(daysOffset * 86400 * 1000); // Convert serial to milliseconds
  //         return date.toISOString(); // Return in ISO format
  //       } else {
  //         // If it's not a number, assume it's already a date string
  //         return new Date(serial).toISOString();
  //       }
  //     };
  
  //     // Debugging: Log the incoming data
  //     console.log('Selected File Data:', selectedFileData);
  
  //     const dataToSave = selectedFileData.map(row => ({
  //       siteId: row.siteName || "", // Default to empty string if undefined
  //       equipmentId: row.equipmentName || "",
  //       timestamp: excelSerialToDate(row.date), // Convert serial date to ISO string
  //       todayEnergy: parseFloat(row.todayEnergy) || 0, // Default to 0 if NaN
  //       exportTotalEnergy: parseFloat(row.exportEnergy) || 0,
  //       importTotalEnergy: parseFloat(row.importEnergy) || 0,
  //       irradiation: parseFloat(row.irradiation) || 0
  //     }));
  
  //     console.log('Data to Save:', dataToSave); // Debugging log
  
  //     const response = await postDgrValueSave(dataToSave);
  //     if (response.success) {
  //       console.log('Data saved successfully');
  //       setIsModalOpen(false);
  //     } else {
  //       console.error('Failed to save data:', response.statusDescription);
  //     }
  //   } catch (error) {
  //     console.error('Error saving data:', error);
  //   }
  // };
  
  
  

  /*-------------------------Table get call --------------------------------------------*/
  const fetchData = async () => {
    setLoading(true);
    try {
      const data = await fetchEquipmentTable();
      if (Array.isArray(data)) {
        setSiteEquipment(data);
        setLoading(false);
      } else if (typeof data === "object" && data !== null) {
        setSiteEquipment([data]);
      }
      setLoading(false);
      console.log(data);
    } catch (e) {
      console.error(e);
    }
  };
  /*---------------------------------- New Equipment Call -----------------------*/
  const handleClose = () => {
    setOpen(false);
    seteditcall([]);
    setEdit(false);
    setEquipment("");
    setPopOpen(false); // Close the menu
    setPopAnchorEl(null); // Reset anchor element
  };
  const handleUploadClick = () => {
    fileInputRef.current.click(); // Programmatically click the hidden file input
    // handleClose();
  };
  const Item = styled(Paper)(({ theme }) => ({}));
  const Total = SiteEquipment.length;
  const Active = SiteEquipment.filter((site) => site.status == 1).length;
  const Inactive = SiteEquipment.filter((site) => site.status == 0).length;
  console.log(Active);
  const paths = [
    { label: "Home", path: "/menu" },
    { label: "Daily Generation Value", path: "dgr" },
  ];
  return (
    <div>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20%",
          }}
        >
          <FadeLoader color="#000440" loading={true} />
        </div>
      ) : (
        <div>
          <CustomSnackbar
            open={snack.open}
            onClose={() => setSnack(initialSnack)}
            autoHideDuration={5000}
            severity={snack.severity}
            message={snack.message}
          />
          <div>
            <Box
              sx={{
                margin: "0 0.5% 0 0.5%",
                display: "flex",
                flexWrap: "wrap",
                "& > :not(style)": {
                  mr: 0,
                  mb: 1,
                  // mt: 2,
                  borderRadius: 0,
                  width: "100%",
                  height: "7.5vh",
                },
              }}
            >
              <Card
                elevation={0}
                style={{
                  display: "flex",
                  width: "101%",
                  height: "6.5vh",
                  borderRadius: "2px 2px 0px 0px",
                  userSelect: "none",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <CustomBreadcrumbs
                    paths={paths || []}
                    separatorSize="18px"
                    fontSize="14px"
                  />
                </div>
              </Card>
            </Box>
          </div>
          <div>
            <Box
              sx={{
                margin: "0 0.5% 0 0.5%",
                display: "flex",
                flexWrap: "wrap",
                "& > :not(style)": {
                  mr: 0,
                  mb: 1,
                  borderRadius: 0,
                  width: "100%",
                  height: "7.5vh",
                },
              }}
            >
              <Card
                elevation={0}
                style={{
                  display: "flex",
                  width: "100%",
                  height: "13vh",
                  borderRadius: "2px 2px 0px 0px",
                  userSelect: "none",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    margin: "0 0 0 30px",
                  }}
                >
                  <div>
                    <Typography className="modal-typo">Site Name *</Typography>
                    <div style={{ marginTop: "5%" }}>
                      <Autocomplete
                        id="combo-box-demo"
                        // options={siteIdName.map((option) => option.siteName)}
                        // defaultValue={edit ? edit.siteName : null}
                        // onChange={(data, event) => handleSite(data, event)}
                        // value={selectedSite}
                        options={siteIdName} // Pass the entire array of site objects
                        getOptionLabel={(option) => option.siteName || ""} // Display only the siteName as the label
                        defaultValue={edit ? edit.siteName : null}
                        onChange={(event, value) =>
                          handleSite(event, value?.siteName)
                        } // Pass siteName to the handleSite function
                        value={
                          siteIdName.find(
                            (site) => site.siteName === selectedSite
                          ) || null
                        }
                        sx={{ width: "14vw" }}
                        ListboxProps={{
                          style: {
                            maxHeight: "200px",
                          },
                        }}
                        size="small"
                        renderInput={(params) => (
                          <TextField {...params} sx={{}} placeholder="IFP" />
                        )}
                      />
                    </div>
                  </div>
                  <div style={{ marginLeft: "5%" }}>
                    <Typography className="modal-typo" sx={{ width: "14vw" }}>
                      Date
                    </Typography>
                    <div style={{ marginTop: "5%" }}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          size="small"
                          // disablePast
                          sx={{ width: "14vw" }}
                          // value={selectedDate}
                          value={dayjs(selectedDate)}
                          onChange={handleDateChange}
                          maxDate={dayjs()} // Prevent future dates from being selected
                          slotProps={{ textField: { size: "small" } }}
                        />
                      </LocalizationProvider>
                    </div>
                  </div>
                </div>
                <div>
                  <Stack spacing={2} direction="row">
                    {/* <Box sx={{ width: "100%" }}> */}
                    <div style={{ margin: "0 30px 0 0" }}>
                      <Tooltip
                        title="Upload Excel"
                        userSelect="none"
                        followCursor
                        PopperProps={toolStyle}
                      >
                        <IconButton
                          variant="text"
                          onClick={handleFileUpload}
                          style={{
                            textTransform: "capitalize",
                            color: "#004AFF",
                            padding: "0",
                            borderRadius: "20px 20px 20px 20px",
                          }}
                          sx={{
                            "&:hover": {
                              backgroundColor: "transparent",
                            },
                          }}
                        >
                          <FileUploadOutlinedIcon
                            style={{
                              fontSize: "25px",
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                      <Menu
                        id="upload-menu"
                        open={popOpen}
                        anchorEl={popAnchorEl}
                        onClose={handleClose}
                        style={{ marginLeft: "-0.5%", marginTop: "0.5%" }}
                      >
                        <MenuItem
                          onClick={() => {
                            handleClose();
                            handleTemplateDownload();
                          }}
                          style={{
                            textTransform: "capitalize",
                            padding: "15px",
                            color: "#1976D2",
                          }}
                        >
                          <SaveAltIcon fontSize="small" />
                          <Typography
                            style={{ marginLeft: "3%", fontSize: "14px" }}
                          >
                            Download Template
                          </Typography>
                        </MenuItem>
                        <MenuItem
                          // onClick={() => {
                          //   handleUploadExcelpopup(setFieldSets, setSnack); // Trigger file upload
                          //   handleClose(); // Close the menu
                          // }}
                          // onClick={handleClick}
                          onClick={handleUploadClick}
                          // onClick={() => fileInputRef.current.click()}
                          style={{
                            textTransform: "capitalize",
                            padding: "15px",
                            color: "#d32f2f",
                          }}
                        >
                          <FileUploadOutlinedIcon fontSize="small" />
                          <Typography
                            style={{ marginLeft: "3%", fontSize: "14px" }}
                          >
                            Upload Excel
                          </Typography>
                        </MenuItem>
                        <input
                          type="file"
                          // accept=".xlsx, .xls" // Allow only Excel file types
                          ref={fileInputRef}
                          style={{ display: "none" }} // Hide the input element
                          onChange={handleFileUpload}
                          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        />

                        <ExcelDataModal
                          open={isModalOpen}
                          handleClose={handleCloseModal}
                          handleSave={handleSave}
                          data={selectedFileData}
                        />
                      </Menu>

                      <Tooltip
                        title="Download Data"
                        userSelect="none"
                        followCursor
                        PopperProps={toolStyle}
                      >
                        <IconButton
                          disabled={!hasDataToDownload()}
                          variant="text"
                          onClick={handleDownload}
                          style={{
                            textTransform: "capitalize",
                            // color: "white",
                            color: "#004AFF",
                            // fontSize: '70px',
                            padding: "0",
                            borderRadius: "20px 20px 20px 20px",
                            marginLeft: "10px",
                          }}
                          sx={{
                            "&:hover": {
                              backgroundColor: "transparent",
                            },
                          }}
                        >
                          <SaveAltIcon
                            style={{
                              //   color: rowsValue.length === 0 ? "grey" : "#004AFF",
                              fontSize: "25px",
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                    </div>

                    <Button
                      variant="contained"
                      style={{
                        borderRadius: "25px",
                        textTransform: "capitalize",
                        margin: "0 20px 0 0",
                      }}
                      onClick={handleSave}
                    >
                      Save
                    </Button>
                    <Button
                      variant="contained"
                      disabled={toBackend}
                      style={{
                        borderRadius: "25px",
                        textTransform: "capitalize",
                        margin: "0 20px 0 0",
                      }}
                    >
                      Cancel
                    </Button>
                    {/* </Box> */}
                  </Stack>
                </div>
              </Card>
            </Box>
          </div>
          <div>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                "& > :not(style)": {
                  m: 1,
                  width: "100%",
                  height: "57vh",
                },
              }}
            >
              <Paper elevation={0} sx={{ overflowY: "auto", height: "100%" }}>
                {sitestatus ? (
                  <div
                    style={{
                      padding: "25px",
                    }}
                  >
                    {fieldSets.map((fieldSet, index) => (
                      <Grid
                        container
                        spacing={2}
                        style={{ marginBottom: "20px" }}
                        key={index}
                      >
                        <Grid item xs={12} sm={6} md={3} lg={2}>
                          <Typography className="modal-typo" gutterBottom>
                            Equipment Name *
                          </Typography>
                          <Typography
                            sx={{
                              width: "100%",
                              padding: "8.5px 14px",
                              fontSize: "1rem",
                              backgroundColor: "white",
                              borderRadius: "4px",
                              minHeight: "1.4375em",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            {fieldSet.equipmentName}
                          </Typography>
                        </Grid>

                        {(fieldSet.equipmentName
                          .toLowerCase()
                          .includes("inverter") ||
                          fieldSet.equipmentName
                            .toLowerCase()
                            .includes("inv")) && (
                          <Grid item xs={12} sm={6} md={3} lg={2}>
                            <Typography className="modal-typo" gutterBottom>
                              Today Energy (kWh) *
                            </Typography>
                            <TextField
                              fullWidth
                              size="small"
                              name="todayEnergy"
                              placeholder="283"
                              value={fieldSet.todayEnergy}
                              // value={fieldSet.todayEnergy !== undefined ? fieldSet.todayEnergy : ""}
                              onChange={(e) =>
                                handleInputChange(e, "todayEnergy", index)
                              }
                            />
                          </Grid>
                        )}

                        {fieldSet.equipmentName
                          .toLowerCase()
                          .includes("energymeter") && (
                          <>
                            <Grid item xs={12} sm={6} md={3} lg={2}>
                              <Typography className="modal-typo" gutterBottom>
                                Export Energy (MWh) *
                              </Typography>
                              <TextField
                                fullWidth
                                size="small"
                                name="exportTotalEnergy"
                                placeholder="384.38"
                                value={fieldSet.exportTotalEnergy}
                                onChange={(e) =>
                                  handleInputChange(
                                    e,
                                    "exportTotalEnergy",
                                    index
                                  )
                                }
                              />
                            </Grid>
                            <Grid item xs={12} sm={6} md={3} lg={2}>
                              <Typography className="modal-typo" gutterBottom>
                                Import Energy (MWh) *
                              </Typography>
                              <TextField
                                fullWidth
                                size="small"
                                name="importTotalEnergy"
                                placeholder="382.46"
                                value={fieldSet.importTotalEnergy}
                                onChange={(e) =>
                                  handleInputChange(
                                    e,
                                    "importTotalEnergy",
                                    index
                                  )
                                }
                              />
                            </Grid>
                          </>
                        )}

                        {fieldSet.equipmentName
                          .toLowerCase()
                          .includes("sensor") && (
                          <Grid item xs={12} sm={6} md={3} lg={2}>
                            <Typography className="modal-typo" gutterBottom>
                              Irradiation *
                            </Typography>
                            <TextField
                              fullWidth
                              size="small"
                              name="irradiation"
                              placeholder="7.83"
                              value={fieldSet.irradiation}
                              onChange={(e) =>
                                handleInputChange(e, "irradiation", index)
                              }
                            />
                          </Grid>
                        )}
                      </Grid>
                    ))}
                  </div>
                ) : (
                  <div
                    style={{
                      position: "fixed",
                      textAlign: "center",
                      left: "40%",
                      top: "61%",
                    }}
                  >
                    <Typography>
                      No equipment has been configured for this site yet
                    </Typography>
                  </div>
                )}
              </Paper>
            </Box>
          </div>
        </div>
      )}
    </div>
  );
}
