import { useTheme } from "@mui/material";

/**
 * removeQuestionMark function removes all question marks from a given string.
 * @param {string} inputString - The input string to process.
 * @returns {string} The processed string with all question marks removed.
 */
export function removeQuestionMark(inputString) {
  /**
   * Using the replace method, we replace all occurrences of the question mark
   * with nothing, effectively removing it from the string.
   */
  return inputString.replace(/\?/g, "");
}

/**
 * Checks if the current theme is in light mode.
 * @returns {boolean} True if the theme is in light mode, false if not.
 */
export function CheckForLight() {
  const theme = useTheme();
  const colorMode = theme.palette.mode;
  // Return true if the theme is in light mode, false if not
  return colorMode === "light";
}

export const roundedUp = (number) => Math.ceil(number * 100) / 100;

/**
 * Removes entries from the given data array that occur after the specified cutoff time.
 *
 * @param {Array} data - The array of data entries to filter.
 * @param {string} cutoff - The cutoff time in the format "HH:MM:SS".
 * @returns {Array} - The filtered array of data entries.
 *
 * @example
 * Given the following data array:
 * const data = [
 *   { timeStamp: '2022-01-01T08:00:00' },
 *   { timeStamp: '2022-01-01T09:00:00' },
 *   { timeStamp: '2022-01-01T10:00:00' },
 *   { timeStamp: '2022-01-01T11:00:00' },
 * ];
 *
 * The following call to removeAfterTime will return the first three entries:
 * const filteredData = removeAfterTime(data, '10:00:00');
 * console.log(filteredData);
 * Output:
 *  [
 *    { timeStamp: '2022-01-01T08:00:00' },
 *    { timeStamp: '2022-01-01T09:00:00' },
 *    { timeStamp: '2022-01-01T10:00:00' },
 *  ]
 */
export function removeAfterTime(data, cutoff) {
  // Split the cutoff time into hours, minutes, and seconds.
  const [cutoffHour, cutoffMinute, cutoffSecond] = cutoff
    .split(":")
    .map(Number);

  // Initialize an empty array to store the filtered data.
  const filteredData = [];

  // Iterate over each entry in the data array.
  for (let entry of data) {
    const stamp = entry.timeStamp ? entry.timeStamp : entry.TimeStamp;
    // Get the time of the current entry.
    const entryTime = new Date(stamp);

    // Extract the hour, minute, and second from the entry time.
    const entryHour = entryTime.getHours();
    const entryMinute = entryTime.getMinutes();
    const entrySecond = entryTime.getSeconds();

    // Check if the entry time is after the cutoff time.
    // If it is, break out of the loop and return the filtered data.
    if (
      entryHour > cutoffHour ||
      (entryHour === cutoffHour && entryMinute > cutoffMinute) ||
      (entryHour === cutoffHour &&
        entryMinute === cutoffMinute &&
        entrySecond > cutoffSecond)
    ) {
      break;
    }

    // If the entry time is not after the cutoff time, add it to the filtered data.
    filteredData.push(entry);
  }

  // Return the filtered data.
  return filteredData;
}

/**
 * Converts the given raw date string to the format 'DD-MM-YYYY HH:MM:SS' and
 * returns the formatted date string.
 *
 * @param {string} rawDate - The raw date string to convert.
 * @returns {string} - The formatted date string.
 */
export function getFormatDate(rawDate) {
  // Create a new UTC Date object from the raw date string.
  const utcDate = new Date(rawDate);

  // Define the options for the date formatter.
  const options = {
    // Format the year as 'numeric'.
    year: "numeric",
    // Format the month as '2-digit'.
    month: "2-digit",
    // Format the day as '2-digit'.
    day: "2-digit",
    // Format the hour as '2-digit'.
    hour: "2-digit",
    // Format the minute as '2-digit'.
    minute: "2-digit",
    // Format the hour in 24-hour format.
    hour12: false,
    // Set the time zone to 'Asia/Kolkata'.
    timeZone: "Asia/Kolkata",
  };

  // Create a new Intl.DateTimeFormat object with the options.
  const formatter = new Intl.DateTimeFormat("en-IN", options);

  // Format the UTC date using the formatter.
  const formattedDate = formatter
    .format(utcDate)
    .replace(/\//g, "-")
    .replace(", ", " ");

  // Log the formatted date to the console.
  console.log(formattedDate, "lastUpdatedTimestamp");

  //if backend time zone logic added return below
  const [Y, M, D] = rawDate.split("T")[0].split("-");
  const [H, MI, _] = rawDate.split("T")[1].split(".")[0].split(":");

  const newDate = `${D}-${M}-${Y} ${H}:${MI}`;

  // Return the formatted date.
  return newDate;
}

/**
 * Transforms the input array to remove entries that have a null capacity
 * or a displayName that contains 'meter' or 'sensor', and maps the remaining
 * entries to an array of objects with `equipmentId` and `capacity` properties.
 *
 * @param {Array} inputArray - The array of input data.
 * @returns {Array} - The transformed array of objects.
 */
export function transformDataForSpecificYield(inputArray) {
  // Check if inputArray is an array; if not, return an empty array
  if (!Array.isArray(inputArray)) {
    console.error("Invalid input: inputArray is not an array.");
    return [];
  }

  // Filter the input array to remove entries that have a null capacity
  // or a displayName that contains 'meter' or 'sensor'.
  return inputArray
    .filter(
      (item) =>
        item.dcCapacity != null && // Check if capacity is not null
        !/meter|sensor/i.test(item.displayName) // Check if displayName does not contain 'meter' or 'sensor'
    )
    .map((item) => ({
      // Map the remaining entries to objects with equipmentId and capacity properties
      equipmentId: item.equipmentId, // Set the equipmentId property to the value of item.equipmentId
      capacity: item.dcCapacity ? item.dcCapacity : 1, // Set the capacity property to the value of item.dcCapacity, or 1 if it is null
    }));
}

/**
 * Filters the given array of roles by the given array of activity names.
 * Only roles where the activityName property is included in the given
 * activityNames array are returned.
 *
 * @param {Array} roles - The array of roles to filter.
 * @param {Array} activityNames - The array of activity names to filter by.
 * @returns {Array} - The filtered array of roles.
 */
export function filterRolesByActivityName(roles, activityNames) {
  const filteredRoles = [];

  // Iterate over each role in the given array of roles.
  for (const role of roles) {
    // Check if the activityName property of the current role is included in
    // the given array of activity names.
    if (activityNames.includes(role.activityName)) {
      // If it is, add the role to the filteredRoles array.
      filteredRoles.push(role);
    }
  }

  // Return the filtered array of roles.
  return filteredRoles;
}

/**
 * Returns an object containing the permissions for a given activity name.
 * The object has properties isPresent (boolean), isActive (boolean), view (boolean),
 * create (boolean), and edit (boolean), which indicate whether the activity is present
 * in the roles array, is active, and whether the user has view, create, and edit permissions.
 *
 * @param {Array} roles - The array of roles to search for the activity name.
 * @param {string} activityName - The name of the activity to search for.
 * @returns {Object} An object with properties isPresent, isActive, view, create, and edit.
 */
export function getActivityPermissions(roles, activityName) {
  // Initialize an object to store the result.
  const result = {
    isPresent: false, // Indicates whether the activity is present in the roles array.
    isActive: false, // Indicates whether the activity is active.
    view: false, // Indicates whether the user has view permission.
    create: false, // Indicates whether the user has create permission.
    edit: false, // Indicates whether the user has edit permission.
  };

  // Iterate over each role in the roles array.
  for (const role of roles) {
    // If the role's activityName matches the given activityName,
    // set the result object properties and break out of the loop.
    if (role.activityName === activityName) {
      result.isPresent = true;
      result.isActive = role.status === 1;
      result.view = Boolean(role.view);
      result.create = Boolean(role.create);
      result.edit = Boolean(role.edit);
      break;
    }
  }

  // Return the result object.
  return result;
}

/**
 * Returns an array of unique data logger IDs from the given data array.
 * Handles null data, empty data, and missing dataLoggerId property.
 *
 * @param {Array} data - The array of data to extract unique data logger IDs from.
 * @return {Array} An array of unique data logger IDs.
 */
export function getUniqueDataLoggerIds(data) {
  if (!data) {
    return []; // Return an empty array if data is null or undefined.
  }

  const uniqueIds = new Set();

  for (const item of data) {
    if (item && item.dataLoggerId !== undefined) {
      uniqueIds.add(item.dataLoggerId);
    }
  }

  return Array.from(uniqueIds);
}

const monthOrder = [
  "JANUARY",
  "FEBRUARY",
  "MARCH",
  "APRIL",
  "MAY",
  "JUNE",
  "JULY",
  "AUGUST",
  "SEPTEMBER",
  "OCTOBER",
  "NOVEMBER",
  "DECEMBER",
];

/**
 * Sorts an array of objects based on the 'timeStamp' property, in
 * the order of the months of the year (January, February, March, etc.).
 *
 * @param {Array} data - The array of objects to sort.
 * @returns {Array} The sorted array of objects.
 */
export const sortedDataByMonth = (data) =>
  data.sort((a, b) => {
    return (
      monthOrder.indexOf(a.timeStamp ? a.timeStamp : a.timestamp) -
      monthOrder.indexOf(b.timeStamp ? b.timeStamp : b.timestamp)
    );
  });
