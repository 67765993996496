import React from "react";
import {
  Modal,
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
} from "@mui/material";

const ExcelDataModal = ({ open, handleClose, handleSave, data }) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="excel-data-modal"
      aria-describedby="modal-to-display-excel-data"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "80%",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          maxHeight: "80vh",
          overflowY: "auto",
          borderRadius: "10px",
        }}
      >
        <div style={{ display: "flex", justifyContent: "flex-end", borderRadius: "10px" }}>
          <Box sx={{ mt: 2, display: "flex", justifyContent: "flex-end" }}>
            <Button
              variant="contained"
              style={{
                borderRadius: "25px",
                textTransform: "capitalize",
                margin: "0 20px 0 0",
              }}
              onClick={handleClose}
            >
              Cancel
            </Button>
          </Box>
          <Box sx={{ mt: 2, display: "flex", justifyContent: "flex-end" }}>
            <Button
              onClick={handleSave}
              variant="contained"
              style={{
                borderRadius: "25px",
                textTransform: "capitalize",
                margin: "0 20px 0 0",
              }}
            >
              Submit
            </Button>
          </Box>
        </div>
        
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          gutterBottom
        >
          Uploaded Excel Data
        </Typography>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="excel data table">
            <TableHead>
              <TableRow>
                <TableCell>Site Name</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Equipment Name</TableCell>
                <TableCell>Today Energy (kWh)</TableCell>
                <TableCell>Export Energy (MWh)</TableCell>
                <TableCell>Import Energy (MWh)</TableCell>
                <TableCell>Irradiation</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>{row.siteName}</TableCell>
                  <TableCell>{row.date}</TableCell>
                  <TableCell>{row.equipmentName}</TableCell>
                  <TableCell>{row.todayEnergy}</TableCell>
                  <TableCell>{row.exportEnergy}</TableCell>
                  <TableCell>{row.importEnergy}</TableCell>
                  <TableCell>{row.irradiation}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Modal>
  );
};

export default ExcelDataModal;