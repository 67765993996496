import React, { useEffect, useState, useMemo } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import Highcharts3D from 'highcharts/highcharts-3d';
import { fetchBarChartGet, fetchBarChartGett } from '../../Api/PieChartDataApi';
import { useTheme, Paper } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { removeQuestionMark } from '../util/common/CommanFunction';
import { decryptPaddedQuery } from '../util/security/Cipher';
import { Padding } from '@mui/icons-material';
Highcharts3D(Highcharts);

const BarChart = (props) => {
  const { sitevalue,statuscall,barchart,filterstatus} = props;
  console.log(sitevalue,barchart);
  const theme = useTheme();
  const colorMode = theme.palette.mode;
  const isLight = colorMode === 'light';

  const [chartOptions, setChartOptions] = useState({});
  const [hasData, setHasData] = useState(false);
  const [data, setData] = useState(null); // State to hold fetched data

  const location = useLocation();
  console.log(location);
  const encryptedQuery = removeQuestionMark(location.search);
  const queryParam = decryptPaddedQuery(encryptedQuery);
  const queryParams = new URLSearchParams(`?${queryParam}`);

  const params = useMemo(() => {
    const parsedParams = {};
    for (const [key, value] of queryParams.entries()) {
      parsedParams[key] = value;
    }
    return parsedParams;
  }, [queryParams]);

  const isFromSiteList = useMemo(
    () => Object.keys(params).length > 0,
    [params]
  );

  console.log(sitevalue);
  console.log(statuscall);
  const fetchDataBarchart = async (sitevalue) => {
    console.log(sitevalue);
    let id =sessionStorage.getItem('id')
    console.log(id);
    try {
let fetchedData;
  if(isFromSiteList ===true ){
      fetchedData = await fetchBarChartGett(params.id);
  }else if(sitevalue>0) {

  fetchedData = await fetchBarChartGett(sitevalue);
  }else{
    fetchedData = await fetchBarChartGet(id);
  }

      console.log('Fetched data:', fetchedData);

      if (fetchedData && fetchedData.ticketTypes) {
        setData(filterstatus===true?barchart:fetchedData);
      } else {
        setData(null);
      }
    } catch (error) {
      console.error('Error fetching data:', error.message);
      setData(null);
    }
  };

  useEffect(() => {
    fetchDataBarchart(sitevalue);
  }, [colorMode, isFromSiteList, params.id, sitevalue,statuscall,barchart,filterstatus]);


  useEffect(() => {
    if (!data) return;

    const ticketTypes = data.ticketTypes;
    const seriesNames = [
      'finishedTicket',
      'AssignedTicket',
      'unfinishedTicket',
      'inProgressTicketCount',
      'createdTicket',
    ];
    const tooltipNames = {
      finishedTicket: 'Finished Ticket',
      AssignedTicket: 'Assigned Ticket',
      unfinishedTicket: 'Unfinished Ticket',
      inProgressTicketCount: 'In Progress Ticket',
      createdTicket: 'Created Ticket',
    };
    const legendNames = {
      finishedTicket: 'Finished',
      AssignedTicket: 'Assigned',
      unfinishedTicket: 'Unfinished',
      inProgressTicketCount: 'In Progress',
      createdTicket: 'Created',
    };
    const categories = Object.keys(ticketTypes);
    const series = seriesNames.map((name) => ({
      name: legendNames[name],
      data: categories.map((category) => ticketTypes[category][name]),
      originalName: name,
    }));

    console.log(series, 'series');
    setChartOptions({
      chart: {
        type: 'column',
        height: 'auto',
        options3d: {
          enabled: false,
          alpha: 10,
          beta: 25,
          depth: 70,
        },
        spacing: [12, 20, 25, 20], // top, right, bottom, left
        backgroundColor: isLight ? '#ffffff' : '#121212',
      },
      title: {
        text: 'Status Specific Chart',
        style: {
          fontSize: '15px',
          fontWeight: 'normal',
          color: isLight ? 'black' : 'white',
        },
      },
      series,
      xAxis: {
        gridLineWidth: 0, 
        categories,
        labels: {
          style: {
            color: isLight ? 'black' : 'white',
          },
        },
      },
      yAxis: {
        title: {
          text: 'Count',
          style: {
            color: isLight ? 'black' : 'white',
          },
        },
        gridLineWidth: 0, 
        tickInterval: 20, 
        labels: {
          formatter: function () {
            return this.value; 
          },
          style: {
            color: isLight ? 'black' : 'white',
          },
        },
      },
      legend: {
        itemStyle: {
          fontSize: '0.8rem',
          color: isLight ? 'black' : 'white',
          cursor: 'pointer',
          textDecoration: 'none',
          fill: 'black',
        },
        itemHoverStyle: {
          color: 'gray', 
        },
      },
      plotOptions: {
        series: {
          depth: 25,
        },
      },
      tooltip: {
        formatter: function () {
          return `<b>${this.key}</b><br>${
            tooltipNames[this.series.options.originalName]
          }: <b>${this.y}</b><br>`;
        },
      },
      credits: {
        enabled: false,
      },
    });

    setHasData(series.some((serie) => serie.data.some((value) => value > 0)));
  }, [data, isLight]);

  return (
    <Paper elevation={0} style={{Padding:"10px"}}>
      <HighchartsReact highcharts={Highcharts} options={chartOptions} />
    </Paper>
  );
};

export default BarChart;