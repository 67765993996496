import axios from 'axios';

const isOEM = sessionStorage.getItem('userTypeId') == 1;

export const fetchequipmentTable = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_EQUIPMENTCONFIG_TABLE}`
    );
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};

export const fetchequipmentTableById = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}${
        isOEM
          ? process.env.REACT_APP_EQUIPMENTCONFIG_TABLE_BYCOMPANY
          : process.env.REACT_APP_EQUIPMENTCONFIG_TABLE_BYID
      }/${
        isOEM
          ? sessionStorage.getItem('companyId')
          : sessionStorage.getItem('customerId')
      }`
    );
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};

export const Equipmentsitedropdown = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}${
        isOEM
          ? process.env.REACT_APP_SITE_DROP_DOWN_BYCOMPANY
          : process.env.REACT_APP_SITE_DROP_DOWN
      }/${
        isOEM
          ? sessionStorage.getItem('companyId')
          : sessionStorage.getItem('customerId')
      }`
      // `${process.env.REACT_APP_SITE_DROP_DOWN}`
    );
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};

export const Equipmentsitedropdowns = async (id) => {
  // let id = Number(sessionStorage.getItem(''));
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_EQUIPMENT}/${id}`
    );
    console.log(response, 'response');
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};

export const Equipmentcategorydropdown = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_EQUIPMENT_CATEGORY}`
    );
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};
export const Equipmentcategorysite = async (Id) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_EQUIPMENT_CATEGORY}/${Id}`
    );
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};
export const Equipmenttypedropdown = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_EQUIPMENT_TYPE}`
    );
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};

export const Equipmentdropdown = async (siteId) => {
  let id = Number(sessionStorage.getItem(''));
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_EQUIPMENT}/${siteId}`
    );
    console.log(response, 'response');
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};
export const statusMasterDrop = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}${
        isOEM
          ? process.env.REACT_APP_STATUS_DROP_BYCOMPANY
          : process.env.REACT_APP_STATUS_DROP
      }/${
        isOEM
          ? sessionStorage.getItem('companyId')
          : sessionStorage.getItem('customerId')
      }`
    );
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};
export const dataLoggerDrop = async (siteId) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_DATA_LOGGER_DROP}/${siteId}`
    );
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};

export const primaryDrop = async (id) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_PRIME_DROP}/${id}`
    );
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};

// ==============Equipment List API=================import axios from "axios";
// export const fetchEquipmentlistTable = async (id) => {
//   try {
//     const response = await axios.get(
//       `http://3.109.2.47:8085/Dashboardsrv/Equipment/getEquipmentDtl=${id}`
//     );

//     return response.data;
//   } catch (error) {
//     console.error('Error posting data:', error);
//     throw error;
//   }
// };

export const postEquipmentSave = async (obj) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_EQUIPMENTCONFIG_SAVE}`,
      obj
    );

    return response.data;
  } catch (error) {
    console.error('Error posting data:', error.message);
    throw error;
  }
};

export const postConfigurationSave = async (data, equipmentId) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_CONFIGURATION_SAVE}/${equipmentId}`,
      data
    );

    return response.data;
  } catch (error) {
    console.error('Error posting data:', error.message);
    throw error;
  }
};

export const putEquipmentconfig = async (data, id) => {
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_EQUIPMENTCONFIG_UPDATE}/${id}`,
      data
    );
    console.log(response, 'response');
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};
// export const fetchSiteEquipmentFilterTable = async (Id) => {
//   try {
//     const response = await axios.get(
//       `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_EQUIPMENT_TABLE_FILTER}/${Id}`
//     );

//     return response.data;
//   } catch (error) {
//     console.error("Error posting data:", error);
//     throw error;
//   }
// };
export const putConfiguration = async (data, id) => {
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_CONFIGURATION_UPDATE}/${id}`,
      data
    );
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};

export const putEquipReplace = async (data, equipId) => {
  try {
    const responseData = await axios.put(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_EQUIP_REPLACE}/${equipId}`,
      data
    );
    return responseData.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
export const postEquipmentexceldata = async (obj) => {
  try {
    const resolvedData = await Promise.all(obj);
    console.log(resolvedData, 'ghj');
    const response = await axios.post(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_EQUIPMENTCONFIG_EXCEL_TABLE}`,
      resolvedData
    );
    return response;
  } catch (error) {
    console.error('Error posting data:', error.message);
    throw error;
  }
};

export const fetchSiteEquipmentFilterTable = async (Id) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_EQUIPMENT_TABLE_FILTER}/${Id}`
    );

    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};
